<template>
  <main class="flex min-h-screen items-center justify-center">
    <article class="prose p-4">
      <ContentDoc />
    </article>
  </main>
</template>

<script>
definePageMeta({
  layout: 'navbar',
  auth: false,
})
</script>
